import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import { getSharedAssetHtml } from '@services/sharedAssetService';

// example of callback: isLoading.value = false;
export function exportToJpg(node, title, titleClasses = 'text-primary font-semibold m-8') {
  const fullHtml = addHtmlWrapperWithTitle(node, title, titleClasses);
  document.body.appendChild(fullHtml);
  const tempStyle = document.createElement('style');
  tempStyle.innerHTML = `
    .sa-question__content {
      background-color: transparent !important;
      filter: none !important;
    }
  `;
  document.head.appendChild(tempStyle);
  domtoimage
    .toJpeg(fullHtml, { quality: 0.95, bgcolor: '#fff' })
    .then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = 'rapport.jpeg';
      link.href = dataUrl;
      link.click();
    })
    .finally(() => {
      document.head.removeChild(tempStyle);
      document.body.removeChild(fullHtml);
    });
}

export function addHtmlWrapperWithTitle(node, title, titleClasses = 'text-primary font-semibold m-8') {
  node;
  const wrapper = document.createElement('div');
  // wrapper.style.backgroundColor = '#fff';
  wrapper.style.padding = '20px';
  // wrapper.style.display = 'flex';
  // wrapper.style.flexDirection = 'column';
  // wrapper.style.alignItems = 'center';
  // wrapper.style.justifyContent = 'center';
  const header = document.createElement('h2');
  header.className = titleClasses;
  header.textContent = title;
  wrapper.appendChild(header);
  wrapper.appendChild(node.cloneNode(true));
  return wrapper;
}

/**
 * Convert pixels to millimeters dynamically.
 * @param {number} pixels - The pixel value to convert.
 * @returns {number} - Value in millimeters.
 */
export function pixelsToMm(pixels) {
  const dpi = calculateDpi();
  return (pixels / dpi) * 25.4;
}

/**
 * Convert millimeters to pixels dynamically.
 * @param {number} mm - The millimeter value to convert.
 * @returns {number} - Value in pixels.
 */
export function mmToPixels(mm) {
  const dpi = calculateDpi();
  return mm * (dpi / 25.4);
}

/**
 * Calculate screen DPI (PPI) dynamically in the browser.
 * @returns {number} - The estimated DPI of the user's screen.
 */
export function calculateDpi() {
  const div = document.createElement('div');
  div.style.width = '1in'; // 1 inch in CSS units
  div.style.visibility = 'hidden';
  document.body.appendChild(div);
  const dpi = div.offsetWidth * window.devicePixelRatio;
  document.body.removeChild(div);
  return dpi;
}

export function removeExternalCSS() {
  const externalCSSLink = document.querySelector("link[href*='widget.css']"); // Target the specific external CSS
  if (externalCSSLink) {
    externalCSSLink.remove(); // Remove the link tag for that external CSS file
  }
}

export function restoreExternalCSS() {
  // You can restore it by re-adding the link or reloading the CSS in a different way
  const head = document.querySelector('head');
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = 'https://wchat.eu.freshchat.com/widget/css/widget.css'; // Add back the CSS
  head.appendChild(link);
}

export function calculateOptimalScaleRatioForImages(images, pageWidthInPix, pageHeightInPix, maxScale = 1.5) {
  if (!images || images.length === 0) {
    return 1; // Default scale if no elements provided
  }
  let optimalScale = maxScale;
  images.forEach(image => {
    const scaleWidth = pageWidthInPix / image.width;
    const scaleHeight = pageHeightInPix / image.height;
    // Calculate the scale to fit the image within the page
    let imageScale = Math.min(scaleWidth, scaleHeight);
    // Clamp the scale within the allowed range
    imageScale = Math.min(imageScale, maxScale);
    // Update the global optimal scale (smallest scale)
    optimalScale = Math.min(optimalScale, imageScale);
  });
  return optimalScale;
}

export function calculateOptimalScaleRatioForHtmlElements(children, pageWidthInPix, pageHeightInPix, maxScale = 1.5) {
  if (!children || children.length === 0) {
    return 1; // Default scale if no elements provided
  }
  let optimalScale = maxScale;
  for (const child of children) {
    const renderedWidth = child.offsetWidth;
    const renderedHeight = child.offsetHeight;
    // Calculate scale ratios
    const scaleWidth = pageWidthInPix / renderedWidth;
    const scaleHeight = pageHeightInPix / renderedHeight;
    // Determine effective scale and clamp
    const childScale = Math.min(scaleWidth, scaleHeight, maxScale);
    // Update global optimal scale
    optimalScale = Math.min(optimalScale, childScale);
  }
  return optimalScale;
}

export function calculateScaleRatioToFitInSpace(node, spaceWidthInPix, spaceHeightInPix, maxScale = 1.5) {
  const width = node.offsetWidth;
  const height = node.offsetHeight;
  const scaleWidth = spaceWidthInPix / width;
  const scaleHeight = spaceHeightInPix / height;
  return Math.min(scaleWidth, scaleHeight, maxScale);
}

export async function generateImages(optimalScale, childrenNodes) {
  if (!childrenNodes || childrenNodes.length === 0) {
    return []; // No images to generate
  }
  // Generate images concurrently for better performance
  const promises = childrenNodes.map(childNode => generateImage(optimalScale, childNode));
  const dataImageUrls = await Promise.all(promises);
  return dataImageUrls.map((dataImageUrl, index) => ({
    element: childrenNodes[index],
    dataImageUrl,
  }));
}

export async function generateImage(optimalScale, element) {
  const originalTransform = element.style.transform;
  const originalBorder = element.style.border;
  // Create a temporary style override
  const tempStyle = document.createElement('style');
  tempStyle.innerHTML = `
    .sa-question__content {
      background-color: white !important;
      filter: none !important;
    }
  `;
  document.head.appendChild(tempStyle);
  try {
    element.style.transform = `none`;
    element.style.border = 'none';
    await new Promise(resolve => setTimeout(resolve, 100)); // Wait to fully render dom
    const dataImageUrl = await domtoimage.toPng(element);
    if (!dataImageUrl) {
      throw new Error('Une erreur est survenue lors de la conversion en image.');
    }
    return dataImageUrl;
  } finally {
    document.head.removeChild(tempStyle);
    element.style.border = originalBorder;
    element.style.transform = originalTransform;
  }
}

export function addTitleToJsPdfDoc(doc, options = {}) {
  const {
    pdfWidthInMm,
    title,
    color = [70, 145, 247],
    fontName = 'helvetica',
    fontSize = 18,
    marginXInMm = 10,
    marginYInMm = 10,
    lineSpacing = 1.2, // Line spacing multiplier
  } = options;
  // Set font properties
  doc.setFont(fontName, 'bold');
  doc.setFontSize(fontSize);
  doc.setTextColor(...color);
  // Calculate the maximum width available for the text
  const maxTextWidth = pdfWidthInMm - 2 * marginXInMm;
  // Split the title into lines that fit within the maxTextWidth
  const splitTitle = doc.splitTextToSize(title, maxTextWidth);
  // Calculate the starting Y position for the text block
  let currentY = marginYInMm;
  // Render each line
  splitTitle.forEach(line => {
    // Measure the width of the current line
    const lineWidth = (doc.getStringUnitWidth(line) * fontSize) / doc.internal.scaleFactor;
    // Center the line within the available width
    const lineX = Math.max(marginXInMm, (pdfWidthInMm - lineWidth) / 2);
    // Draw the text line
    doc.text(line, lineX, currentY);
    // Move to the next line
    currentY += fontSize * lineSpacing * 0.35278;
  });
  return currentY; // Return doc for chaining
}

export async function exportToPdfOneGraphPerPage({
  container,
  title,
  marginInMm = 15,
  landscape = false,
  pdfWidthInMm = 210,
  pdfHeightInMm = 297,
  minScale = 0.8,
  maxScale = 1.5,
}) {
  if (landscape) {
    let tempWidth = pdfWidthInMm;
    pdfWidthInMm = pdfHeightInMm;
    pdfHeightInMm = tempWidth;
    tempWidth = null;
  }
  const childrenNodes = Array.from(container.children); // Sub-elements (blocks)
  const margin = mmToPixels(marginInMm); // in pixels
  // Calculate available page dimensions (without margins)
  const doc = new jsPDF({
    orientation: landscape ? 'landscape' : 'portrait',
    unit: 'mm',
    format: [pdfWidthInMm, pdfHeightInMm],
  });
  const pageWidthInPix = mmToPixels(pdfWidthInMm) - margin * 2;
  const pageHeightInPix = mmToPixels(pdfHeightInMm) - margin * 2;
  const optimalScale = calculateOptimalScaleRatioForHtmlElements(childrenNodes, pageWidthInPix, pageHeightInPix, minScale, maxScale);
  let currentY = addTitleToJsPdfDoc(doc, { pdfWidthInMm, title, marginXInMm: marginInMm, marginYInMm: marginInMm });
  // imageDatas = [{ element:, dataImageUrl: }]
  const imageDatas = await generateImages(optimalScale, childrenNodes);
  // add images to doc one by page
  for (let index = 0; index < imageDatas.length; index++) {
    const imageData = imageDatas[index];
    const htmlWidthInPix = imageData.element.offsetWidth;
    const htmlHeightInPix = imageData.element.offsetHeight;
    const imgWidth = pixelsToMm(htmlWidthInPix * optimalScale);
    const imgHeight = pixelsToMm(htmlHeightInPix * optimalScale);
    // Calculate the X and Y position to center the image
    const positionX = (pdfWidthInMm - imgWidth) / 2; // Center horizontally
    let positionY = 30;
    if (index === 0) {
      positionY = currentY + 10;
    }
    await doc.addImage(imageData.dataImageUrl, 'JPEG', positionX, positionY, imgWidth, imgHeight);
    if (index + 1 < imageDatas.length) {
      await doc.addPage();
    }
  }
  doc.save('report.pdf');
}

export async function exportContainerToPdf({
  container,
  title,
  landscape = false,
  marginInMm = 15,
  pdfWidthInMm = 210,
  pdfHeightInMm = 297,
  minScale = 0.8,
  maxScale = 1.5,
  gapInMm = 10,
}) {
  if (landscape) {
    let tempWidth = pdfWidthInMm;
    pdfWidthInMm = pdfHeightInMm;
    pdfHeightInMm = tempWidth;
    tempWidth = null;
  }
  const marginInPix = mmToPixels(marginInMm); // in pixels
  // Create pdf document
  const pdf = new jsPDF({
    orientation: landscape ? 'landscape' : 'portrait',
    unit: 'mm',
    format: [pdfWidthInMm, pdfHeightInMm],
  });
  // Add title and track current vertical position on the page in mm
  let currentY = addTitleToJsPdfDoc(pdf, { pdfWidthInMm, title, marginXInMm: marginInMm, marginYInMm: marginInMm });
  // Calculate available page dimensions (without margins)
  const pageWidthInPix = mmToPixels(pdfWidthInMm) - marginInPix * 2;
  const pageHeightInPix = mmToPixels(pdfHeightInMm) - marginInPix * 2;

  // Get container dimensions
  const containerWidth = container.offsetWidth; // in pixels
  const containerHeight = container.offsetHeight; // in pixels
  const optimalContainerScaleRatio = calculateOptimalScaleRatioForHtmlElements([container], pageWidthInPix, pageHeightInPix, minScale, maxScale);

  // If the container fits on one page with 20% small adjustment size, we generate one page wit all
  if (optimalContainerScaleRatio > minScale) {
    const dataImageUrl = await generateImage(optimalContainerScaleRatio, container);
    const fitWidth = pixelsToMm(containerWidth * optimalContainerScaleRatio);
    const fitHeight = pixelsToMm(containerHeight * optimalContainerScaleRatio);
    const positionX = (pdfWidthInMm - fitWidth) / 2; // Center horizontally
    await pdf.addImage(dataImageUrl, 'JPEG', positionX, currentY, fitWidth, fitHeight);
  } else {
    const childrenNodes = Array.from(container.children); // Sub-elements (blocks)
    // let currentX = marginInMm; // Start horizontal position (after left margin)
    let rowHeightInMm = 0; // Tracks the height of the tallest element in the current row
    let rowElements = []; // Holds the elements in the current row for horizontal centering
    let rowWidthInMm = 0; // Total width of the elements in the current row

    for (let childNode of childrenNodes) {
      // Calculate scale based on space available
      const scale = calculateScaleRatioToFitInSpace(childNode, pageWidthInPix, pageHeightInPix, maxScale);

      // Generate image data for the element
      const dataImageUrl = await generateImage(scale, childNode);

      // Check if the dataImageUrl is valid
      if (!dataImageUrl) {
        console.error('Failed to generate image data for childNode:', childNode);
        continue; // Skip to next element if there's an error
      }

      const childWidthInMm = pixelsToMm(childNode.offsetWidth * scale);
      const childHeightInMm = pixelsToMm(childNode.offsetHeight * scale);

      // Calculate the total width if we add this element (including the gap)
      const totalRowWidth = rowWidthInMm + rowElements.length * gapInMm + childWidthInMm;

      // Check if the current row width exceeds the available width on the page
      if (totalRowWidth > pdfWidthInMm - marginInMm) {
        // Once we can't fit the element in the current row, process the current row
        const totalRowWidthForCentering = rowWidthInMm + (rowElements.length - 1) * gapInMm; // Total width of elements + gaps
        let rowStartX = (pdfWidthInMm - totalRowWidthForCentering) / 2; // Calculate starting position to center the row

        // Place elements in the row with correct positioning
        for (let i = 0; i < rowElements.length; i++) {
          const { dataImageUrl, width, height } = rowElements[i];

          // Place each element
          pdf.addImage(
            dataImageUrl,
            'JPEG',
            rowStartX, // Horizontal positioning calculated to center the row
            currentY, // Vertical positioning, same for all elements in the row
            width,
            height,
          );

          rowStartX += width + gapInMm; // Move horizontal position for the next element
        }

        // Move to the next row
        currentY += rowHeightInMm + gapInMm; // Move vertically by the height of the tallest element

        // If the next element doesn't fit on the page, add a new page
        if (currentY + childHeightInMm > pdfHeightInMm - marginInMm) {
          pdf.addPage(); // Add new page
          currentY = marginInMm; // Reset vertical position to top of the page
        }

        // currentX = marginInMm; // Reset the horizontal position for the next row
        rowHeightInMm = 0; // Reset the height tracker for the next row
        rowWidthInMm = 0; // Reset total width tracker for the next row
        rowElements = []; // Reset the row elements array
      }

      // Add the current element to the rowElements array
      rowElements.push({
        dataImageUrl,
        width: childWidthInMm,
        height: childHeightInMm,
      });
      rowWidthInMm += childWidthInMm; // Update the total width of the row

      // Update the rowHeightInMm to ensure the row is tall enough for all elements
      rowHeightInMm = Math.max(rowHeightInMm, childHeightInMm); // Take the height of the tallest element
    }

    // After the loop ends, process the last row (if any elements are left)
    if (rowElements.length > 0) {
      const totalRowWidthForCentering = rowWidthInMm + (rowElements.length - 1) * gapInMm;
      let rowStartX = (pdfWidthInMm - totalRowWidthForCentering) / 2;

      for (let i = 0; i < rowElements.length; i++) {
        const { dataImageUrl, width, height } = rowElements[i];

        // Check if dataImageUrl is valid before adding the image
        if (!dataImageUrl) {
          console.error('Missing image data for element in last row:', rowElements[i]);
          continue;
        }

        // Place the element
        pdf.addImage(dataImageUrl, 'JPEG', rowStartX, currentY, width, height);

        rowStartX += width + gapInMm;
      }
    }
  }

  // Save the PDF
  pdf.save('rapport.pdf');
}

export async function loadTemplateAndGenerateImage(shareable, shareableType) {
  try {
    const res = await getSharedAssetHtml({
      shareableId: shareable.id,
      shareableType: shareableType,
    });
    const sharedAssetHtml = res.data.shared_asset_html;

    const tempDiv = document.createElement('div');
    tempDiv.setAttribute('id', 'tempDiv');
    tempDiv.innerHTML = sharedAssetHtml;
    document.body.appendChild(tempDiv);

    await ensureImagesLoaded(tempDiv);

    // Needed because of CORS errors from style comming from freshchat (chatbot)
    const freshchatLinks = document.querySelectorAll('link[href*="freshchat.com"]');
    freshchatLinks.forEach(link => link.remove());

    const dataUri = await domtoimage.toPng(tempDiv, { backgroundColor: null, crossOrigin: 'Anonymous' });
    document.body.removeChild(tempDiv);

    // Reinjection of freshchat
    freshchatLinks.forEach(link => document.head.appendChild(link));

    return dataUri;
  } catch (error) {
    const tempDiv = document.getElementById('tempDiv');
    document.body.removeChild(tempDiv);
    console.log(error);
    throw new Error(error);
  }
}

const ensureImagesLoaded = container => {
  const images = container.querySelectorAll('img');
  const promises = Array.from(images).map(img => {
    if (img.complete) return Promise.resolve();
    return new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });
  });
  return Promise.all(promises);
};
